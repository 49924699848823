.dashboard-wrapper {
  display: flex;
  gap: 20px;
}

.dashboard-left,
.dashboard-right {
  flex: 1;
}

.dashboard-center {
  flex: 2;
}
.dashboard-center-right {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 1200px) {
  .dashboard-center-right {
    flex-direction: column;
    gap: 0;
  }

  .dashboard-center,
  .dashboard-right {
    flex: none;
    width: 100%;
  }
}
/* --------------------------project---------------------------------------------------------------------- */
.project-card {
  background-color: var(--background-block);
  padding: 16px;
  margin-bottom: 20px;
}

/* .project-card-header {
  padding: 10px;
} */

.project {
  padding: 10px;
  margin: 2px;
  background-color: #fff;
}

.project-card-title {
  font-size: 1.2rem;
  font-weight: 500;
  /* color: #fff; */
}

.project-card-sort {
  cursor: pointer;
  padding: 10px;
  border: solid 1px #888;
  border-radius: 5px;
}

.project-card-sort .label {
  margin-right: 20px;
}

.project-card-bottom:hover {
  color: var(--hover-grey);
}

.tasks-card {
  /* background-color: #9babc4; */
  background-color: var(--background-block);
  padding: 16px;
  /* min-width: 280px; */
}

.task-date_deadline {
  width: 20%;
}

.agenda-card-body {
  /* min-height: 300px; */
  margin-top: 8px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.agenda-header {
  padding: 20px;
  border-bottom: solid 1px #888;
}

.agenda-content {
  padding: 20px;
}

.nav-item {
  cursor: pointer;
}

.next,
.prev {
  margin: 0 10px;
}

.actionerTodayBlock{
  min-width: 500px;
}
.grid-container {
  display: grid;
  width: 300px;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: 10px; 
}
.grid-item {
  width: 100%; 
  padding: 6px; 
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.memberTaskWrapper {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 8px;
}
.isActionerTaskWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

@media screen and (max-width: 992px) {
  .actionerTodayBlock{
    min-width: 300px;
  }
  .isActionerTaskWrapper {
    grid-template-columns: 1fr;
  }
}

/* ======================AddLeaveDaysForm.js====================================================================== */
.range-datepicker{
  width: 300px;
}

